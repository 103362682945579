import { gql } from 'graphql-request';

import {
  TaskBurning,
  TaskID,
  TaskImplementationDate,
  TaskLdCustomerID,
  TaskLdProductID,
  TaskLdSku,
  TaskName,
  TaskNanoID,
  TaskProjectID,
  TaskProjectName,
  TaskProjectNanoID,
  TaskProjectTeamImageFile,
  TaskProjectTeamName,
  TaskProjectTeamNanoID,
  TaskProjectTeamUseLdApiAt,
  TaskProjectUUID,
  TaskState,
  TaskStatus,
  TaskUUID,
  TaskVersionMessageBody,
  TaskVersionMessageID,
  TaskVersionMessageUUID,
  TaskVersionNumber,
  TaskVisibleForClient
} from '../tasksTypes';
import {
  MessageTaskMark,
  MessageTaskSupportMark
} from '../../messages/messagesTypes';

export interface FetchShowTaskQueryResponse {
  id: TaskID;
  uuid: TaskUUID;
  nanoId: TaskNanoID;
  name: TaskName;
  status: TaskStatus;
  burning: TaskBurning;
  visibleForClient: TaskVisibleForClient;
  mark: MessageTaskMark;
  supportMark: MessageTaskSupportMark;
  implementationDate: TaskImplementationDate;
  ldCustomerId: TaskLdCustomerID;
  ldProductId: TaskLdProductID;
  ldSku: TaskLdSku;
  project: {
    id: TaskProjectID;
    uuid: TaskProjectUUID;
    nanoId: TaskProjectNanoID;
    name: TaskProjectName;
    team: {
      name: TaskProjectTeamName;
      nanoId: TaskProjectTeamNanoID;
      image: {
        file: TaskProjectTeamImageFile;
      };
      useLdApiAt: TaskProjectTeamUseLdApiAt;
    };
  };
  state: TaskState;
  versionNumber: TaskVersionNumber;
  versionMessage: {
    id: TaskVersionMessageID;
    uuid: TaskVersionMessageUUID;
    body: TaskVersionMessageBody;
  };
}

export const FETCH_SHOW_TASK_QUERY = gql`
  query ShowTask($uuid: ID!) {
    task(uuid: $uuid) {
      id
      uuid
      nanoId
      name
      status
      burning
      visibleForClient
      mark
      supportMark
      implementationDate
      ldCustomerId
      ldProductId
      ldSku
      project {
        id
        uuid
        nanoId
        name
        team {
          name
          nanoId
          image {
            file
          }
          useLdApiAt
        }
      }
      versionNumber
      versionMessage {
        id
        uuid
        body
      }
      state
    }
  }
`;
